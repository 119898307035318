<template>
	<Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" :class="{ 'd-none':(add_dialog || edit_dialog)}">
    <MDBCardBody>
      <transition name="fade">
        <PageLoading v-if="PageLoading"></PageLoading>
      </transition>
      <transition name="fade">
        <AxiosLoading v-if="loading"></AxiosLoading>
      </transition>
      <MDBContainer fluid class="mt-3">
        <MDBRow>
          <MDBCol class="mb-2 text-start" md="2">
            <MDBSelect v-model:options="language.items" :label="$t('COMMON.LANGUAGE')" v-model:selected="lang"></MDBSelect>
          </MDBCol>
          <MDBCol class="mb-2 text-end">
            <MDBBtn color="info" @click="add_dialog = true">{{ $t("PRODUCT-CATEGORY.ADD-ITEM") }}</MDBBtn>  
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBTable
            class="table datatable-table"
            responsive
          >
            <thead>
              <tr>
                <th width="50">#</th>
                <th>{{ $t("COMMON.MAINCATEGORY")}}</th>
                <th>{{ $t("COMMON.SUBCATEGORY")}}</th>
                <th width="50">{{ $t("COMMON.EDIT")}}</th>
                <th width="50">{{ $t("COMMON.DELETE")}}</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item ,k) in Categorys" :key="k">
                <tr scope="row">
                  <td></td>
                  <td>{{ item.name[lang].name }}</td>
                  <td></td>
                  <td>
                    <MDBBtn color="info" @click="EditUUid = item.uuid" class="px-3 py-2">
                      <MDBIcon icon="edit" iconStyle="fas" class="text-white"/>
                    </MDBBtn>
                  </td>
                  <td>
                    <MDBBtn color="danger" @click="DeleteAction(item.uuid)" class="px-3 py-2">
                      <MDBIcon icon="trash-alt" iconStyle="fas" class="text-white"/>
                    </MDBBtn>
                  </td>
                </tr>
                <template v-for="(sub, i) in item.sub" :key="i">
                  <tr scope="row">
                    <td></td>
                    <td></td>
                    <td>{{ sub.name[lang].name }}</td>
                    <td>
                      <MDBBtn color="info" @click="EditUUid = sub.uuid" class="px-3 py-2">
                        <MDBIcon icon="edit" iconStyle="fas" class="text-white"/>
                      </MDBBtn>
                    </td>
                    <td>
                      <MDBBtn color="danger" @click="DeleteAction(sub.uuid)" class="px-3 py-2">
                        <MDBIcon icon="trash-alt" iconStyle="fas" class="text-white"/>
                      </MDBBtn>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
            <tfoot>
              <tr></tr>
            </tfoot>
          </MDBTable>
        </MDBRow>
      </MDBContainer>
    </MDBCardBody>
  </MDBCard>
	<ProductCategoryForm action="insert" v-model="add_dialog" :data="Categorys"></ProductCategoryForm>
	<ProductCategoryForm action="update" v-model="edit_dialog" v-model:uuid="EditUUid" :data="Categorys"></ProductCategoryForm>
</template>

<style scoped>
  .sub-category {
    list-style: auto;
    text-align: start;
  }
  .sub-category li {
    border-bottom: 1px solid;
    width: fit-content;
  }
  .sub-div {
    display: inline-block;
    margin-right: 10px;
  }
</style>

<script>
import { MDBContainer, MDBIcon, MDBBtn, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBTable, MDBSelect } from 'mdb-vue-ui-kit';
import Breadcrumbs from '@/components/Breadcrumbs';
import ProductCategoryForm from '@/components/Form/Product/ProductCategoryForm';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { ref, reactive } from "vue";
import i18nService from "@/core/services/i18n.service.js";
import PageLoading from '@/components/Elements/PageLoading';
import AxiosLoading from '@/components/Elements/AxiosLoading';

export default {
  name: "ProductCategory",
  components: {
		MDBContainer,
		MDBIcon,
		Breadcrumbs,
		ProductCategoryForm,
		MDBBtn,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBTable,
    MDBSelect,
    PageLoading,
    AxiosLoading
  },
  setup() {
    const PageLoading = ref(true);
    const lang = ref(i18nService.getActiveLanguage());
    const language = reactive({
      items: i18nService.languages.map(item => { item.selected = (item.lang == i18nService.getActiveLanguage()); return item;}),
      selected: ""
    });
    const Categorys = ref({});
    const add_dialog = ref(false);
    const edit_dialog = ref(false);
    const loading = ref(true);
    const EditUUid = ref("");
    const Common = CommonService;

    const GetTable = function() {
      return new Promise(resolve => {
        loading.value = true;
        ApiService.query(
          "/Admin/v1/ProductCategory",
          {
            params: {}
          }
        ).then(response => {
          if (response.status == 200) {
            Categorys.value = response.data.data;
            loading.value = false;
            resolve(response);
          }
        });
      });
    };

    const DeleteAction = function(uuid) {
      CommonService.DeleteSwal().then(result => {
        if (result.isConfirmed) {
          ApiService.delete("/Admin/v1/ProductCategory/" + uuid).then(
            response => {
              if (response.status == 200) {
                CommonService.AxiosHandle(response);
                GetTable();
              }
            }
          );
        }
      });
    };

		return {
      add_dialog,
      edit_dialog,
      loading,
      EditUUid,
      Common,
      GetTable,
      DeleteAction,
      Categorys,
      language,
      lang,
      PageLoading
    };
  },
  mounted() {
    Promise.all([
      this.GetTable(false)
    ]).then(() => {
      this.PageLoading = false;
    });
  },
  watch: {
		options: {
      handler() {
        this.GetTable();
      },
      deep: true
    }
  },
  provide () {
    return {
      GetTable: this.GetTable
    }
  }
};
</script>